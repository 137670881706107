:root {
    --primary-color: #fff;
    --secondary-color: #333;
    --transition-speed: 0.3s;
    --box-shadow-light: 0 2px 5px rgba(0, 0, 0, 0.15);
    --box-shadow-dark: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.Gallery {
    padding: 20px;
}

.Gallery h2, .Gallery p {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 20px;
}

.Gallery h2 {
    font-size: 30px;
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: 10px;
}

.Gallery p {
    font-size: 20px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
}

.image-grid img, .image-grid video {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: var(--box-shadow-light);
    transition: all var(--transition-speed) ease-in-out;
}

.image-grid img:hover, .image-grid video:hover {
    transform: scale(1.05);
    box-shadow: var(--box-shadow-dark);
}

.modal {
    overflow-y: auto;
    transition: opacity 0.5s;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);


    transition: all var(--transition-speed) ease-in-out;
    margin: auto;
}

.modal img, .modal video {
    max-width: 90%;
    max-height: 90%;
    margin: 20px;
    box-shadow: var(--box-shadow-dark);
}

.modal img:hover, .modal video:hover {
    transform: scale(1.05);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 24px;
    z-index: 1000;
}

.prev, .next {
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--primary-color);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.prev:hover, .next:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.8);
}

.button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.Gallery .modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Gallery .modal img, .Gallery .modal video {
    margin: auto;
}

@media (max-width: 600px) {
    .Gallery .modal {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Gallery .modal img, .Gallery .modal video {
        box-shadow: var(--box-shadow-light);
        border-radius: 20%;
        max-width: 100%;
        max-height: 70%;
        margin: 20px 0;
    }

    .Gallery .button-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .Gallery .prev, .Gallery .next {
        position: static;
        transform: none;
        margin: 20px auto;
        padding: 15px 30px;
        font-size: 18px;
    }
}