.Header {
    background-color: $background-color;
    display: flex;
    justify-content: space-between;
    padding: 2%;    
    border-radius: 5px;
    box-shadow: 0px 0px 10px #ccc;


    .logo {
        border-radius: 10%;
        width: 100%;
        height: 150px;
    }

    .navigation {
        width: 44%;
        height: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 3%;
        margin-right: 3%;

        .nav-active {
            text-decoration: none;
            color: #fff;
            border-bottom: $primary-color solid;
            margin-bottom: -5%;
        }

        .nav {
            text-decoration: none;
            color: #fff;
        }

    }
}
@media screen and (max-width:2560px) {
    .Header {
        .navigation {
            width: 20%;
        }
    }
}

@media screen and (max-width:2000px) {
    .Header {
        .navigation {
            width: 25%;
        }
    }
}

@media screen and (max-width:1600px) {
    .Header {
        .navigation {
            width: 30%;
        }
    }
}

@media screen and (max-width:1300px) {
    .Header {
        .navigation {
            width: 35%;
        }
    }
}

@media screen and (max-width:1100px) {
    .Header {
        .navigation {
            width: 40%;
        }
    }
}

@media screen and (max-width:950px) {
    .Header {
        .navigation {
            width: 45%;
        }
    }
}

@media screen and (max-width:800px) {
    .Header {
        .navigation {
            width: 55%;
        }
    }
}

@media screen and (max-width:700px) {
    .Header {
        flex-direction: column;
        align-items: center;

        .logo {
            height: 55px;
        }

        .navigation {
            margin: auto;
            width: 90%;
            padding-top: 5%;
            padding-bottom: 10%;
        }
    }
}

@media screen and (max-width:600px) {
    .Header {
        .logo {
            width: 70%;
            height: auto;
            display: block;
            margin: auto;
        }
    }
}


@media screen and (max-width:425px) {
    .Header {
        .navigation {
            font-size: 15px;
        }
    }
}

@media screen and (max-width:375px) {
    .Header {
        .navigation {
            font-size: 12px;
        }
    }
}