// Where.scss

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: $secondary-color;
    padding: 20px;
}

h2 {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
}

p {
    font-size: 18px;
    margin-bottom: 10px;
}

a {
    color: #007bff;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        text-decoration: underline;
    }
}

.container {
    background: #00224D;
    color: #fff;
    padding: 20px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 600px) {
    body {
        padding: 10px;
    }

    h2 {
        font-size: 24px;
    }

    p, a {
        font-size: 16px;
    }

    .container {
        padding: 10px;
        margin: 10px auto;
    }

    img, iframe {
        max-width: 100%;
    }
}