.Footer {
    display: flex;
    justify-content: space-between;
    background-color: $background-color;
    color: #fff;
    text-align: center;
    padding: 2%;
    margin-top: 5%;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #ccc;
    
    .navigation {
        width: 44%;
        height: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 3%;
        margin-right: 3%;

        .nav-active {
            text-decoration: none;
            color: #fff;
            border-bottom: $primary-color solid;
            margin-bottom: -5%;
        }

        .nav {
            text-decoration: none;
            color: #fff;
        }

    }
    @media (max-width: 768px) {
        .navigation {
            width: 95%;
            margin: 0 auto;
        }
    }
}
