.Home {
    .content {
        padding: 20px;
        color: #fff;
        font-family: Arial, sans-serif;
        line-height: 1.6;

        h2 {
            color: #fff;
            font-size: 30px;
            margin-bottom: 20px;
            text-align: center;
            border-bottom: 2px solid #333;
            padding-bottom: 10px;
        }

        p {
            color: #fff;
            font-size: 18px;
            margin-bottom: 10px;
        }

        video {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .photo-gallery {
            display: flex;
            justify-content: space-around;
            margin-bottom: 20px;

            img {
                max-width: 30%;
                height: auto;
                box-shadow: 0px 0px 10px #ccc;
            }


        }
        .team-members {
            display: flex;
            justify-content: space-around;
    
            img {
                max-width: 200px;
                max-height: 200px;
            }
    
            p {
                text-align: center;
            }
        }
    }
}